import React from "react";
import PlacesAutocomplete from "./GooglePlacesAutocomplete";

import { geocodeByAddress } from 'react-google-places-autocomplete';

import { getFleet } from "../../reducers/fleet";
import { useSelector } from "react-redux";

import { checkLocationExists, createLocation } from "../../lib/api";
import Status, { processState } from "./Status";

const LocationTool = ({
   showModal, setShowModal
  }:{showModal:boolean, setShowModal: Function}) => {
    const [process, updateProcess] = React.useState(processState.idle);
    const currentFleet = useSelector(getFleet);
    const [payload, updatePayload] = React.useState({});
    const setPayload = (payload:any) => {
        console.log("payload", payload);
        updatePayload(payload);
    }
    const [googleSearchResult, updateGoogleSearchResult] = React.useState({});


    const setGoogleSearchResult = async (result:any) => {
        updateGoogleSearchResult(result);
        updateProcess(processState.checkingIfAddressExists);
        // @ts-ignore
        const {exists} = await checkLocationExists(result.label);
        if (!exists) {
            updateProcess(processState.gettingDetailsFromMapService);
            const geocode = await geocodeByAddress(result.label);
            handleAddressSelected(geocode[0], result.label);
        } else if(exists){
            updateProcess(processState.addressExistsAlready);
        } else {
            updateProcess(processState.error); 
            console.error("Error in searching for existing address");  
        }
    }

    const handleClick = async () => {
        updateProcess(processState.postingLocation);
        const location = await createLocation(payload);
        if(location && location.uuid) {
            updateProcess(processState.idle);
            setShowModal(false);
            console.log("Location created successfully", location);
        } else {
            updateProcess(processState.error);
            console.error("Error in creating location");
        }
    };
   
    // we need to use the Label from the first result to match the data inputs the Installer Mobile App uses
    const handleAddressSelected = (result:any, label: string) => {
      // @ts-ignore
      window.investigate = result;
      let address = '';
      let city = '';
      let state = '';
      let country = '';
      let postcode = 'xxxx-yyyy';
      for (let i = 0; i < result.address_components.length; i++) {
        // eslint-disable-next-line camelcase
        const { short_name, types } = result.address_components[i];
        if (types.includes('street_number')) {
          address = `${short_name} ${address}`;
        }
        if (types.includes('route')) {
          address = `${address} ${short_name}`;
        }
        if (types.includes('locality')) {
          city = `${short_name}`;
        }
        if (types.includes('administrative_area_level_1')) {
          state = `${short_name}`;
        }
        if (types.includes('country')) {
          country = `${short_name}`;
        }
        if (types.includes('postal_code')) {
          postcode = postcode.replace('xxxx', short_name);
        }
        if (types.includes('postal_code_suffix')) {
          postcode = postcode.replace('yyyy', short_name);
        }
      }
      postcode = postcode.replace('-yyyy', '');
      postcode = postcode.replace('xxxx', '');
      const geocode = {
        type: 'Point',
        coordinates: [result.geometry.location.lng(), result.geometry.location.lat()],
      };
      const newLocationPayload = {
        address,
        city,
        state,
        country,
        label,
        geocode,
        postcode,
        fleet: currentFleet.id
      };
      const payload = { body: { ...newLocationPayload } };
      setPayload(payload);
      updateProcess(processState.addressDoesNotExist);
    };
    
    if(!showModal) return null;
    return (
        <div className="generic-modal-wrapper">
            <div className="generic-modal">
                <button className="generic-modal-close" onClick={() => setShowModal(false)}>X</button>
                <div className="generic-modal-header">
                    <h2>Location Tool</h2>
                </div>
                <div className="location-search">            
                    <div style={{width: '100%', display:'flex', flexDirection:'column'}}>
                            <h3>Make a new Location:</h3>
                            <PlacesAutocomplete
                                setGoogleSearchResult={setGoogleSearchResult}
                            />
                            <Status status={process} />
                            <br />
                            {processState.addressDoesNotExist === process && 
                                <button data-testid="add-new-address" onClick={handleClick}>Click Here to Create the New Address</button>}
                            {processState.addressDoesNotExist !== process && 
                                <button disabled onClick={void(0)}>Click Here to Create the New Address</button>}
                            {processState.error === process && 
                                <p style={{color:'red'}}>Error in creating location</p>}
                        </div>
                </div>
            </div>
        </div>
    );
};

export default LocationTool;
